import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { push, createMatchSelector, getSearch } from 'connected-react-router';
import { Dispatch } from 'redux';
import { frontloadConnect } from 'react-frontload';
import { PageLanding, Props } from 'components/pages/PageLanding/PageLanding';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import {
  landingPageFetchedSelector,
  landingPageSelector,
  skipLandingPageFrontloadRequestSelector,
} from 'modules/landingPagesContent/selectors';
import {
  fetchLandingPageContent,
  resetModule,
  resetSkipLandingPageFrontloadRequest,
} from 'modules/landingPagesContent/actions';
import { isAuthenticationModalVisibleSelector } from 'modules/layouts/selectors';
import { isPageStorefrontSelector, isPageHomeSelector } from 'modules/router';
import { isAuthSelector, userHasPrefSelector } from 'modules/user/selectors';
import qs from 'query-string';

const mapActionCreators = (dispatch: Dispatch) => ({
  async fetchLandingPageContent(slug: string): Promise<void> {
    await dispatch(fetchLandingPageContent(slug)).catch(() => {
      return dispatch(push(getPath(Routes.NOT_FOUND), { fetchError: true }));
    });
  },
  resetModule(): void {
    dispatch(resetModule());
  },
  resetSkipLandingPageFrontloadRequest(): void {
    dispatch(resetSkipLandingPageFrontloadRequest());
  },
});

const mapStateToProps = (state: State.Root) => {
  const matchSelector: any = createMatchSelector(getPath(Routes.CAMPAIGNS));
  const slugSelector = createSelector(matchSelector, (match: any): string => match?.params.slug || '');
  const isAuth: boolean | null = isAuthSelector(state);
  const userHasPref: boolean = userHasPrefSelector(state);
  const isUserOnBoarded = Boolean(isAuth) && userHasPref;

  return {
    isAuth,
    slug: slugSelector(state),
    landingPageFetched: landingPageFetchedSelector(state),
    landingPage: landingPageSelector(state),
    scrollNormal: isAuthenticationModalVisibleSelector(state),
    isPageStoreFront: isPageStorefrontSelector(state),
    isPageHome: isPageHomeSelector(state),
    skipLandingPageFrontloadRequest: skipLandingPageFrontloadRequestSelector(state),
    parsedQueryParams: qs.parse(getSearch(state)),
    isUserOnBoarded,
  };
};

const frontload = async (props: Props) => {
  if (props.skipLandingPageFrontloadRequest && !props.parsedQueryParams?.sku) {
    return props.resetSkipLandingPageFrontloadRequest();
  }
  return props.fetchLandingPageContent(props.slug);
};

const options = {
  onUpdate: true,
  _experimental_updateFunc: (prevProps: Props, newProps: Props) => prevProps.slug !== newProps.slug,
};

export const PageLandingContainer = connect(
  mapStateToProps,
  mapActionCreators
)(frontloadConnect(frontload, options)(PageLanding));
