import React from 'react';
import { Loader } from 'semantic-ui-react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { ContentfulHelpers } from 'mxp-utils';
import { useGoogleAds } from 'hooks/useGoogleAds';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { SocialSharing } from 'components/molecules/SocialSharing/SocialSharing';
import { Footer } from 'components/molecules/Footer/Footer';
import { PageHelmet } from 'components/atoms/PageHelmet/PageHelmet';
import { StickyLogInRegisterFooter } from './StickyLogInRegisterFooter';
import { isMobileViewPort, getViewportHeight, isServer } from 'utils';
import { useAutoAddProductSkuAndPromo } from 'hooks/useAutoAddProductSkuAndPromo';
import { Product, Contentful } from 'mxp-schemas';
import { BlockRenderer } from 'components/organisms/BlockRenderer/BlockRenderer';
import { BlockLandingPageRenderer } from 'components/organisms/BlockRenderer/BlockLandingPageRenderer';
import { ExternalAdBanner, ExternalAdBannerType } from 'components/organisms/ExternalAdBanner/ExternalAdBanner';

export interface Props {
  isAuth: boolean | null;
  landingPageFetched: boolean;
  landingPage: Contentful.LandingPages.ParsedPage | null;
  slug: string;
  scrollNormal: boolean;
  isPageStoreFront: boolean;
  isPageHome: boolean;
  skipLandingPageFrontloadRequest: boolean;
  isUserOnBoarded: boolean;
  fetchLandingPageContent: (slug: string) => void;
  resetSkipLandingPageFrontloadRequest: () => void;
  resetModule: () => void;
  parsedQueryParams?: Product.ProductQueryParams;
}

export const PageLanding: React.FC<Props> = ({
  isAuth,
  landingPageFetched,
  landingPage,
  scrollNormal,
  slug,
  isPageStoreFront,
  isPageHome,
  isUserOnBoarded,
  resetModule,
}) => {
  useGoogleAds(landingPage?.externalAdsConfig);
  useAutoAddProductSkuAndPromo();

  const [headerHeight, setHeaderHeight] = React.useState(177);
  const [isFullPagePagination, setIsFullPagePagination] = React.useState(false);

  const targetContainer: any = React.createRef();
  const containerContentRef: any = React.createRef();

  React.useEffect(() => {
    if (containerContentRef?.current && targetContainer?.current) {
      return setHeaderHeight(targetContainer.current.clientHeight - containerContentRef.current.clientHeight);
    }
  }, [targetContainer, containerContentRef]);

  React.useEffect(() => {
    if (isServer) return;
    setIsFullPagePagination(Boolean(landingPage?.useFullPagePagination) && !isSmallViewportHeight && !isMobile);
  }, [landingPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => resetModule, [resetModule]);
  const footerHeight: number = !isAuth ? 163 : 103; // px
  const isMobile: boolean = isMobileViewPort();
  const viewportHeight: number | null = getViewportHeight();
  const isSmallViewportHeight: boolean = Boolean(viewportHeight && viewportHeight < 670 + headerHeight); // 847px min supported height, 670px - min block height

  if (!landingPageFetched) {
    return (
      <HeaderPageWrapper>
        <Loader active>Loading</Loader>
        <StyledFooter isAuth={isAuth} />
        {!isAuth && <StickyLogInRegisterFooter />}
      </HeaderPageWrapper>
    );
  }

  const renderPage = (): React.ReactNode => {
    const blockRender = {
      blocks: landingPage?.landingPageBlocks as Contentful.ModularContent.Block[],
      isFullPagePagination,
      headerHeight,
      footerHeight,
      isMobile,
      isAuth,
      isUserOnBoarded,
      targetContainer,
      containerContentRef,
      isHomePage: landingPage?.default,
    };

    return (
      <>
        <HeaderPageWrapper contentRef={targetContainer}>
          {landingPage?.externalAdsConfig?.topAdBanner && <ExternalAdBanner type={ExternalAdBannerType.TOP} />}
          {landingPage?.landingPageBlocks?.length ? (
            <BlockLandingPageRenderer blockRenderLandingPages={blockRender} />
          ) : null}
          {landingPage?.externalAdsConfig?.bottomAdBanner && <ExternalAdBanner type={ExternalAdBannerType.BOTTOM} />}
          <StyledFooter isAuth={isAuth} />
          {!isAuth && <StickyLogInRegisterFooter />}
          <SocialSharing title={landingPage?.metaTitle} isSticky />
        </HeaderPageWrapper>
      </>
    );
  };
  return (
    <>
      <PageHelmet
        title={landingPage?.metaTitle}
        meta={{
          title: landingPage?.metaTitle,
          description: landingPage?.metaDescription,
        }}
        openGraph={
          isPageStoreFront
            ? {
                title: landingPage?.metaTitle,
                description: landingPage?.metaDescription,
                image: landingPage?.ogImage && `https:${landingPage.ogImage}`,
              }
            : !isPageHome
            ? {
                title: landingPage?.title,
                description: landingPage?.metaDescription,
                image: landingPage?.ogImage && `https:${landingPage.ogImage}`,
              }
            : {
                image: landingPage?.ogImage && `https:${landingPage.ogImage}`,
              }
        }
        isNoIndex={landingPage?.noIndex}
      />
      {isFullPagePagination ? (
        <>
          <div style={{ width: '100%' }}>
            {landingPage?.landingPageBlocks.map(
              (block: Contentful.ModularContent.Block, index: number, arr: Contentful.ModularContent.Block[]) => {
                const withHeader: boolean = !index;
                const withFooter: boolean = arr.length - 1 === index;
                const isFirstBlock: boolean = index === 0;

                if (block) {
                  if ((block as any).contentType !== ContentfulHelpers.CONTENT_TYPES.TOOLKIT_PAGE_BLOCK) {
                    const landingPageBlock = {
                      block,
                      isFullPagePagination,
                      isUserOnBoarded,
                      headerHeight,
                      footerHeight,
                      withHeader,
                      withFooter,
                      ...{
                        withExternalAd: Boolean(
                          (withHeader && landingPage?.externalAdsConfig?.topAdBanner) ||
                            (withFooter && landingPage?.externalAdsConfig?.bottomAdBanner)
                        ),
                      },
                      targetContainer,
                      containerContentRef,
                      isFirstBlock,
                      isMobile,
                      isAuth,
                    };
                    return (
                      <>
                        <BlockRenderer blockRenderLandingPage={landingPageBlock} />
                      </>
                    );
                  }

                  const toolkitBlock = {
                    block: block as State.ToolkitPageBlock,
                    idx: index,
                    key: '',
                  };

                  return (
                    <>
                      <BlockRenderer blockRenderToolkit={toolkitBlock} />
                    </>
                  );
                }
              }
            )}
            {!isAuth && <StickyLogInRegisterFooter />}
            <SocialSharing title={landingPage?.metaTitle} isSticky />
          </div>
        </>
      ) : (
        <StyledDiv>{renderPage()}</StyledDiv>
      )}
    </>
  );
};

interface FooterProps {
  isAuth: boolean | null;
}

const StyledFooter = styled(Footer)<ThemedStyledProps<FooterProps, DefaultTheme>>`
  ${props =>
    !props.isAuth &&
    `
    ::after {
      display: block;
      content: '';
      height: ${props.theme.pxToRem(60)};
  `}
`;

const StyledDiv = styled.div`
  ${props => props.theme.mediaQueries.desktopOnly} {
    height: ${props => props.theme.pxToRem(1)};
    min-height: 100%;
  }
`;
